import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown, ArrowLeft, CheckCircle, HelpCircle, Image, Info } from 'react-feather'
import ReactGA from 'react-ga'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import { ButtonConfirmed, ButtonError, ButtonGray, ButtonLight, ButtonPrimary } from '../../components/Button'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, Dots, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import { useUSDCValue } from '../../hooks/useUSDCPrice'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useActiveWeb3React } from '../../hooks/web3'
import { Field, typeInput } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSingleHopOnly } from '../../state/user/hooks'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { getTradeVersion } from '../../utils/getTradeVersion'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { BigNumber, Contract } from 'ethers'
import { formatEther, formatUnits, isAddress } from 'ethers/lib/utils'
import ERC20 from '../../abis/erc20.json'
import MUNYSWAP from '../../abis/munyswap.json'
import MUNY from '../../abis/muny.json'
import useToast, { PendingTx, SuccessfulTx } from '../../state/swap/useToast'
import { parseUnits } from '@ethersproject/units'
import { dispatch } from 'd3'
import { isNumber } from 'util'
import { LightCard, OutlineCard } from '../../components/Card'
import Row from '../../components/Row'
import { size } from 'polished'

export default function Swap({ history }: RouteComponentProps) {
  const addToast = useToast()
  const SWAPCONTRACT = '0xfeBb7b158FF50f7914E4786416147808500B3BAC'
  const TOKEN2 = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
  const TOKEN1 = '0x0000000000f0be9b330fadfd141b63b93e1c3c84'
  const { account, library } = useActiveWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const maxAllowance = BigNumber.from(2).pow(256).sub(1)
  const [hasSetAllowance, set_hasSetAllowance] = useState(false)
  const [treasurytoken, setTreasuryToken] = useState(0)
  const [burnedSupply, setBurnedSupply] = useState(0)
  const [totalSupply, setTotalSupply] = useState(0)
  const [userSupply, setUserSupply] = useState(0)
  const [inputT, set_inputToken] = useState(TOKEN1)
  let erc20 =
    // @ts-ignore
    isAddress(inputT) && !!ERC20 && !!library ? new Contract(inputT, ERC20, library.getSigner(account)) : undefined

  // effect hook for updating data
  useEffect(() => {
    // update the ui elements
    async function updateUIStates() {
      {
        // @ts-ignore
        const allowance = await erc20.allowance(account, SWAPCONTRACT)
        const hasSetAllowance = allowance._hex === '0x00' ? false : true
        set_hasSetAllowance(hasSetAllowance)
        console.log('allow')
        console.log(hasSetAllowance)

        const munyswap =
          // @ts-ignore
          isAddress(TOKEN1) && !!MUNY && !!library
            ? // @ts-ignore
              new Contract(TOKEN1, MUNY, library)
            : undefined

        // @ts-ignore
        const treasurydao = await munyswap.treasuryDao()
        console.log(treasurydao)
        // @ts-ignore
        const treasurytoken1 = await munyswap.balanceOf(treasurydao)
        setTreasuryToken(treasurytoken1)
        console.log('test')
        console.log(treasurytoken1)

        // @ts-ignore
        const burned = await munyswap.burnedSupply()
        console.log('burned')
        console.log(burned)
        setBurnedSupply(burned)

        // @ts-ignore
        const totalsupply = await munyswap.totalSupply()
        console.log('total')
        console.log(totalsupply)
        setTotalSupply(totalsupply)

        // @ts-ignore
        const usersupply = await munyswap.balanceOf(account)
        console.log('usersupply')
        console.log(usersupply)
        setUserSupply(usersupply)
      }
    }

    if (account) {
      updateUIStates()
    }

    // schedule every 15 sec refresh
    const timer = setInterval(() => {
      if (account) {
        updateUIStates()
      }
    }, 30000)

    // clearing interval
    return () => clearInterval(timer)
  }, [account, inputT])

  console.log(library)
  console.log(hasSetAllowance)

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]

  console.log('test')
  console.log(loadedUrlParams)
  console.log(loadedInputCurrency)
  console.log(loadedOutputCurrency)

  const theme = useContext(ThemeContext)

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get version from the url
  const toggledVersion = useToggledVersion()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v2Trade,
    v3TradeState: { trade: v3Trade, state: v3TradeState },
    toggledTrade: trade,
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(toggledVersion)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  console.log(parsedAmounts)

  const fiatValueInput = useUSDCValue(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDCValue(parsedAmounts[Field.OUTPUT])
  const priceImpact = computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  const {
    state: signatureState,
    signatureData,
    gatherPermitSignature,
  } = useERC20PermitFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient,
    signatureData
  )

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    if (priceImpact && !confirmPriceImpactWithoutFee(priceImpact)) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then((hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })
        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade),
            singleHopOnly ? 'SH' : 'MH',
          ].join('/'),
        })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [
    swapCallback,
    priceImpact,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade,
    singleHopOnly,
  ])

  // warnings on the greater of fiat value price impact and execution price impact
  const priceImpactSeverity = useMemo(() => {
    const executionPriceImpact = trade?.priceImpact
    return warningSeverity(
      executionPriceImpact && priceImpact
        ? executionPriceImpact.greaterThan(priceImpact)
          ? executionPriceImpact
          : priceImpact
        : executionPriceImpact ?? priceImpact
    )
  }, [priceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isArgentWallet &&
    (approvalState === ApprovalState.NOT_APPROVED ||
      approvalState === ApprovalState.PENDING ||
      (approvalSubmitted && approvalState === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async function doSwap() {
    let direction = 0
    if (inputT === TOKEN1) {
      direction = 0
    } else {
      direction = 1
    }
    console.log(direction)
    console.log(formattedAmounts[Field.INPUT])
    const munyswap =
      // @ts-ignore
      isAddress(SWAPCONTRACT) && !!MUNYSWAP && !!library
        ? // @ts-ignore
          new Contract(SWAPCONTRACT, MUNYSWAP, library.getSigner(account))
        : undefined
    try {
      console.log(parseUnits(formattedAmounts[Field.INPUT], 8))
      console.log(direction)
      let swapAmount

      if (direction === 1) {
        swapAmount = parseUnits(formattedAmounts[Field.INPUT], 6)
      } else {
        swapAmount = parseUnits(formattedAmounts[Field.INPUT], 8)
      }

      // @ts-ignore
      const { hash } = await munyswap.swapTokens(swapAmount, direction)
      const { hide: hidePending } = addToast({
        // eslint-disable-next-line react/jsx-no-undef
        body: <PendingTx hash={hash} />,
        type: 'loading',
        hideAfter: 0,
      })

      // @ts-ignore
      const receipt = await library.waitForTransaction(hash)

      // @ts-ignore
      hidePending()

      if (receipt.status !== 1) {
        // @ts-ignore
        throw new Error(receipt.logs[0])
      }

      // @ts-ignore
      addToast({
        // eslint-disable-next-line react/jsx-no-undef
        body: <SuccessfulTx hash={hash} />,
        type: 'success',
      })
    } catch (e) {
      // @ts-ignore
      addToast({ body: e.message, type: 'error' })
    }
  }

  async function approve() {
    try {
      // @ts-ignore
      const { hash } = await erc20.approve(SWAPCONTRACT, maxAllowance)
      const { hide: hidePending } = addToast({
        // eslint-disable-next-line react/jsx-no-undef
        body: <PendingTx hash={hash} />,
        type: 'loading',
        hideAfter: 0,
      })

      // @ts-ignore
      const receipt = await library.waitForTransaction(hash)

      // @ts-ignore
      hidePending()

      if (receipt.status !== 1) {
        // @ts-ignore
        throw new Error(receipt.logs[0])
      }

      // @ts-ignore
      addToast({
        // eslint-disable-next-line react/jsx-no-undef
        body: <SuccessfulTx hash={hash} />,
        type: 'success',
      })

      set_hasSetAllowance(true)
    } catch (e) {
      // @ts-ignore
      addToast({ body: e.message, type: 'error' })
    }
  }

  function setToken() {
    if (inputT === TOKEN1) {
      set_inputToken(TOKEN2)
      erc20 =
        // @ts-ignore
        isAddress(TOKEN2) && !!ERC20 && !!library ? new Contract(TOKEN2, ERC20, library.getSigner(account)) : undefined
    } else {
      set_inputToken(TOKEN1)
      erc20 =
        // @ts-ignore
        isAddress(TOKEN1) && !!ERC20 && !!library ? new Contract(TOKEN1, ERC20, library.getSigner(account)) : undefined
    }
  }

  function format_friendly(input: string, decimals: any) {
    const words = input.split('.')

    const slicer = words[1].slice(0, decimals)
    const returner = words[0] + '.' + slicer

    return returner
  }

  function numberWithCommas(x: number) {
    return x
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  function numberWithCommasString(x: string) {
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <NetworkAlert />
      <Wrapper id="tte">
        <Row justify="center">
          <h1>
            <Text color="white">{formatUnits(userSupply, 8)} MUNY</Text>
          </h1>
        </Row>
        <Row>
          <AutoColumn gap={'md'} justify={'space-between'}>
            <LightCard>
              <p>Burned Supply</p>
              <p>{numberWithCommasString(format_friendly(formatUnits(burnedSupply, 8), 1))} MUNY</p>
            </LightCard>
          </AutoColumn>
          <AutoColumn gap={'md'}>&#8205; &#8205; &#8205;</AutoColumn>
          <AutoColumn gap={'md'} justify={'space-between'}>
            <LightCard>
              <p>Current Supply</p>
              <p>{numberWithCommasString(format_friendly(formatUnits(totalSupply, 8), 1))} MUNY</p>
            </LightCard>
          </AutoColumn>
          <AutoColumn gap={'md'}>&#8205; &#8205; &#8205;</AutoColumn>
          <AutoColumn gap={'md'}>
            <LightCard>
              <p>Treasury Supply</p>
              <p>{numberWithCommasString(format_friendly(formatUnits(treasurytoken, 8), 1))} MUNY</p>
            </LightCard>
          </AutoColumn>
        </Row>
      </Wrapper>
      <AppBody>
        <Wrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <AutoColumn gap={'md'}>
            <div style={{ display: 'relative' }}>
              <CurrencyInputPanel
                label={
                  independentField === Field.OUTPUT && !showWrap ? <Trans>From (at most)</Trans> : <Trans>From</Trans>
                }
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={showMaxButton}
                currency={currencies[Field.INPUT]}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                fiatValue={fiatValueInput ?? undefined}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases={true}
                id="swap-currency-input"
              />
              <ArrowWrapper clickable>
                <ArrowDown
                  size="16"
                  onClick={() => {
                    setToken()
                    onSwitchTokens()
                  }}
                  color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.text1 : theme.text3}
                />
              </ArrowWrapper>
              <CurrencyInputPanel
                // @ts-ignore
                value={
                  inputT === TOKEN2
                    ? isNaN(parseFloat(formattedAmounts[Field.INPUT]))
                      ? '0'
                      : numberWithCommas(parseFloat(formattedAmounts[Field.INPUT]) / 1.2)
                    : isNaN(parseFloat(formattedAmounts[Field.INPUT]))
                    ? '0'
                    : numberWithCommas(parseFloat(formattedAmounts[Field.INPUT]) * 1.2)
                }
                onUserInput={handleTypeOutput}
                label={independentField === Field.INPUT && !showWrap ? <Trans>To (at least)</Trans> : <Trans>To</Trans>}
                showMaxButton={false}
                hideBalance={false}
                fiatValue={fiatValueOutput ?? undefined}
                priceImpact={priceImpact}
                currency={currencies[Field.OUTPUT]}
                onCurrencySelect={handleOutputSelect}
                otherCurrency={currencies[Field.INPUT]}
                showCommonBases={true}
                id="swap-currency-output"
              />
            </div>
            <div>
              {hasSetAllowance ? (
                <ButtonError
                  onClick={() => {
                    doSwap()
                  }}
                  id="swap-button"
                  error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                >
                  <Text fontSize={20} fontWeight={500}>
                    <Trans>Swap</Trans>
                  </Text>
                </ButtonError>
              ) : (
                <ButtonError
                  onClick={() => {
                    approve()
                  }}
                  id="swap-button"
                  error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                >
                  <Text fontSize={20} fontWeight={500}>
                    <Trans>Approve</Trans>
                  </Text>
                </ButtonError>
              )}
            </div>
          </AutoColumn>
        </Wrapper>
      </AppBody>
      <AppBody>
        <Wrapper id="tte1">
          <Row justify="center">
            <ColumnCenter>
              <ButtonError
                onClick={() => {
                  // @ts-ignore
                  window
                    .open(
                      'https://app.uniswap.org/#/swap?inputCurrency=0x0000a1c00009a619684135b824ba02f7fbf3a572&outputCurrency=ETH',
                      '_blank'
                    )
                    .focus()
                }}
                id="swap-button"
                error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
              >
                <Text fontSize={20} fontWeight={500}>
                  <Trans>Get USDC On Uniswap</Trans>
                </Text>
              </ButtonError>
            </ColumnCenter>
          </Row>
        </Wrapper>
      </AppBody>
      <Wrapper id="tte1">
        <Row justify="center" style={{ position: 'absolute', left: 0, right: 0, bottom: -100 }}>
          <AutoColumn gap={'md'} justify={'space-between'}>
            <a href="https://github.com/Keychain-Inc/Muny/raw/main/Muny.pdf" style={{ textDecoration: 'none' }}>
              <Text color="white">Whitepaper</Text>
            </a>
          </AutoColumn>
          <AutoColumn gap={'md'}>&#8205; &#8205; &#8205;</AutoColumn>
          <AutoColumn gap={'md'} justify={'space-between'}>
            <a
              href="https://twitter.com/ProjectMuny"
              target="_blank"
              style={{ textDecoration: 'none' }}
              rel="noreferrer"
            >
              <Text color="white">Twitter</Text>
            </a>
          </AutoColumn>
          <AutoColumn gap={'md'}>&#8205; &#8205; &#8205;</AutoColumn>
          <AutoColumn gap={'md'} justify={'space-between'}>
            <a href="https://discord.gg/wvTmsdTcU7" style={{ textDecoration: 'none' }}>
              <Text color="white">Discord</Text>
            </a>
          </AutoColumn>
        </Row>
      </Wrapper>
    </>
  )
}
